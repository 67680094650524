<template>
  <div class="bg">
    <div class="cen">
      <div class="text-center">
        <img
          src="logo192.png"
          alt="logo-lotto-vip"
          style="margin-top: 200px;"
        >
      <!-- button router-link to="/Lottery" -->
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invcode: this.$route.params.invcode,
    }
  },
  mounted() {
    if (this.$route.params.invcode) {
      this.invcode = this.$route.params.invcode
    }
  },
  methods: {
    LinkToLine() {
      window.location.href = 'https://line.me/R/ti/p/@727bkukk'
    },
  },
}
</script>

<style scoped>
.bg {
  height: 100vh;
  width: 100%;
  background-image: url(/bgh.png);
  background-size: contain;
  background-repeat: repeat;
  background-color: rgb(153, 0, 0);
}
img {
    width: 150px;
    height: 150px;
    /* position: absolute; */
    /* margin: auto; */
}
</style>
